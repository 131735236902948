export const ruleInfo = [
    {
        name: '拉新注册',
        val: 'register'
    },
    {
        name: '拉新付费',
        val: 'pay'
    }
];
export const status = [{
    text: '启用中',
    value: 1
}, {
    text: '已停用',
    value: 2
}];
