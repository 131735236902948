export const initForm = {
    register: { // 拉新注册
        success_num: 1, // 达成人数
        award_inviter_score: '', // 邀请积分
        award_inviter_subscription: '', // 邀请订阅
        award_invitee_score: '', // 被邀请积分
        award_invitee_subscription: '', // 被邀请订阅
        month_score_limit: undefined // 每月获取积分上限
    },
    pay: { // 拉新付费
        success_num: 1, // 达成人数
        award_inviter_score: '', // 邀请积分
        award_inviter_subscription: '', // 邀请订阅
        award_invitee_score: '', // 被邀请积分
        award_invitee_subscription: '', // 被邀请订阅
        month_score_limit: undefined // 每月获取积分上限
    }
};
export const formItems = [{
    title: '条件',
    item: [{
        label: '达成人数',
        prop: 'success_num',
        min: 1,
        max: 50,
        prefix: '每',
        suffix: '人'
    }]
}, {
    title: '邀请者奖励',
    item: [{
        label: '积分',
        prop: 'award_inviter_score',
        min: 0,
        max: 100
    }, {
        label: '订阅',
        prop: 'award_inviter_subscription',
        suffix: '天',
        min: 0,
        max: 100
    }, {
        label: '每月获取积分上限',
        prop: 'month_score_limit',
        min: 0,
        max: 500
    }]
}, {
    title: '被邀请者奖励',
    item: [{
        label: '积分',
        prop: 'award_invitee_score',
        min: 0,
        max: 100
    }, {
        label: '订阅',
        prop: 'award_invitee_subscription',
        suffix: '天',
        min: 0,
        max: 100
    }]
}];
export const rules = {
    success_num: [
        { required: true, message: '请输入达成人数', trigger: 'blur' }
    ]
};
