<template>
    <div class="aops-pull-new-activity-rule">
        <div class="aop-back" @click="handleBack">
            <i class="iconfont aop-back-icon">&#xe647;</i><span>返回</span>
        </div>
        <div v-loading="isLoading" class="aop-activity-rule-wrap">
            <div class="aop-activity-title">规则设置</div>
            <el-form
                ref="form"
                :model="titleForm"
                :rules="titleRule"
                class="aop-title-form"
            >
                <el-form-item
                    label="活动名称"
                    prop="activity_title"
                >
                    <el-input
                        v-model="titleForm.activity_title"
                        placeholder="请输入活动名称"
                    />
                </el-form-item>
            </el-form>
            <div v-for="item in ruleInfo" :key="item.val" class="aop-activity-rule">
                <div class="aop-type-text">{{item.name}}</div>
                <el-form
                    :ref="item.val"
                    :model="form[item.val]"
                    :rules="rules"
                    label-width="82px"
                    inline
                >
                    <div v-for="ele in formItems" :key="ele.title">
                        <div class="aop-rule-title">{{ele.title}}</div>
                        <template v-for="child in ele.item">
                            <el-form-item
                                :key="child.prop"
                                :label="child.label"
                                :prop="child.prop"
                            >
                                {{child.prefix || ''}}
                                <el-input-number
                                    v-model="form[item.val][child.prop]"
                                    :min="child.min"
                                    :max="child.max"
                                    :precision="0"
                                    :controls="false"
                                    @blur="(e) => handleBlur(e, item.val, child.prop)"
                                />
                                {{child.suffix || ''}}
                            </el-form-item>
                        </template>
                    </div>
                </el-form>
            </div>
        </div>

        <div class="aop-activity-footer">
            <el-button type="primary" class="aop-confirm-button" :loading="btnLoading" @click="handleConfirm">保存并使用</el-button>
        </div>
    </div>
</template>

<script>
import Api from '@/api/promotions';
import { ruleInfo } from '../data';
import { initForm, rules, formItems } from './activity_rule_data';

export default {
    /**
     * data
     * @returns {*}
    */
    data() {
        /**
         * 活动名称校验
         * @param {*} rule 规则
         * @param {*} value 值
         * @param {*} callback 返回
        */
        const validateName = (rule, value, callback) => {
            const newVal = value ? value.trim() : '';
            if (!value || !newVal) {
                callback('请输入活动名称');
            } else {
                callback();
            }
        };
        return {
            formItems,
            ruleInfo,
            titleForm: {
                activity_title: ''
            },
            form: { ...initForm },
            rules,
            isLoading: false,
            btnLoading: false,
            titleRule: {
                activity_title: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { validator: validateName, trigger: 'blur' },
                    { min: 1, max: 20, message: '最多可输入20个字', trigger: 'blur' }
                ]
            }
        };
    },
    /**
     * mounted
    */
    mounted() {
        this.getActivityInfo();
    },
    methods: {
        /**
         * 确认提交
         */
        getActivityInfo() {
            this.isLoading = true;
            Api.getActivityInfo().then((res) => {
                if (res.data.code === 10200) {
                    const data = res.data.data || {};
                    const { register = {}, pay = {} } = data.activity_rule || {};
                    this.form = {
                        register: {
                            ...register,
                            month_score_limit: register.month_score_limit === '' ? undefined : register.month_score_limit
                        },
                        pay: {
                            ...pay,
                            month_score_limit: pay.month_score_limit === '' ? undefined : pay.month_score_limit
                        }
                    };
                    this.titleForm.activity_title = data.activity_title;
                } else {
                    this.$$error(res.data.message);
                }
                this.isLoading = false;
            }).catch(() => {
                this.isLoading = false;
            });
        },
        /**
         * 返回
        */
        handleBack() {
            this.$router.push('/promotions/pull-new-management');
        },
        /**
         * handleBlur
         * @param {string} e input 值
         * @param {string} type 付费/注册
         * @param {string} prop 字段
         */
        handleBlur(e, type, prop) {
            const { value } = e.target;
            this.form[type][prop] = prop === 'month_score_limit' ? value || undefined : value || 0;
        },
        /**
         * 每月获取积分上限 校验
         * @param {*} val val
         * @returns {boolean}
         */
        handleValidlimit(val) {
            if (val.month_score_limit !== undefined && (val.month_score_limit < val.award_inviter_score)) {
                return false;
            }
            return true;
        },
        /**
         * 点击确认按钮
        */
        async handleConfirm() {
            const config = await Api.activityConfig();
            if (config.data.code === 10200) {
                const resData = config.data.data?.items || [];
                const data = resData.length ? resData[0] : {};
                if (data.activity_status === 1) {
                    // 确认一下活动状态，已经启用的，不可修改
                    this.$$warning('活动已启用，不可变更规则设置');
                    return;
                }
            }
            let res = false;
            let registerValid = false;
            let payValid = false;
            this.$refs.form.validate((valid) => {
                if (valid) { res = true; }
            });
            this.$refs.register[0].validate((valid) => {
                if (valid) {
                    registerValid = true;
                }
            });
            this.$refs.pay[0].validate((valid) => {
                if (valid) {
                    payValid = true;
                }
            });
            if (res && registerValid && payValid) {
                const limitValid = this.handleValidlimit(this.form.register);
                const paylimitValid = this.handleValidlimit(this.form.pay);
                if (!limitValid || !paylimitValid) {
                    this.$$error('每月获取积分上限不可小于邀请者奖励积分');
                    return;
                }
                this.submit();
            }
        },
        /**
         * 确认提交
         */
        submit() {
            this.btnLoading = true;
            const { register, pay } = this.form;
            Api.activityEditRule({
                activity_title: this.titleForm.activity_title,
                activity_rule: {
                    register: {
                        ...register,
                        month_score_limit: register.month_score_limit === undefined ? '' : register.month_score_limit
                    },
                    pay: {
                        ...pay,
                        month_score_limit: pay.month_score_limit === undefined ? '' : pay.month_score_limit
                    }
                }
            }).then((res) => {
                if (res.data.code === 10200) {
                    this.$$success(`${this.titleForm.activity_title}规则设置成功`);
                    this.handleBack();
                } else {
                    this.$$error(res.data.message);
                }
                this.btnLoading = false;
            }).catch(() => {
                this.btnLoading = false;
            });
        }
    }
};
</script>
<style lang="less">
@import "./activity_rule.less";
</style>
